var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('layout-3',{attrs:{"name":"signature-standards-complete","title":_vm.$t('signature_standards.target_none.aes.title'),"subtitle":_vm.$t('signature_standards.target_none.aes.text'),"image":{
    src: `signature-standards-increase-to-max-${_vm.language}`,
    width: 521,
    height: 540,
  }}},[_c('v-btn',{attrs:{"nuxt":"","to":{
      name: _vm.skipMobileId ? 'profile-signature-standards-legislation' : 'profile-signature-standards-mobile-id',
    },"block":"","color":"info","large":""}},[_vm._v("\n    "+_vm._s(_vm.$t('signature_standards.target_none.aes.increase'))+"\n  ")]),_vm._v(" "),_c('strong',{staticClass:"mx-auto my-4"},[_vm._v("\n    "+_vm._s(_vm.$t('global.signature_standards.or'))+"\n  ")]),_vm._v(" "),_c('v-btn',{attrs:{"exact":"","block":"","type":"","color":"info","outlined":"","large":"","data-cy":"backToProfile"},on:{"click":_vm.goBack}},[_vm._v("\n    "+_vm._s(_vm.$t('signature_standards.target_none.aes.back'))+"\n  ")])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }