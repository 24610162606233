import Layout3 from '@/components/layouts/Layout3.vue';
export default defineComponent({
  components: {
    Layout3: Layout3
  },
  props: {
    skipMobileId: Boolean
  },
  setup: function setup() {
    var _storeToRefs = storeToRefs(useLanguageStore()),
      language = _storeToRefs.language;
    return {
      language: language
    };
  },
  methods: {
    goBack: function goBack() {
      this.$emit('back');
    }
  }
});