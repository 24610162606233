<template>
  <layout-3
    name="signature-standards-complete"
    :title="$t('increase.qes_aes.success.title')"
    :subtitle="$t('increase.qes_aes.success.text')"
    :image="{ src: 'crossing-the-finish-line', width: 535, height: 494 }"
  >
    <v-btn data-cy="cta" nuxt block type="" color="info" large @click="goBack">
      {{ $t('increase.qes_aes.success.cta') }}
    </v-btn>
  </layout-3>
</template>

<script lang="ts">
import Vue from 'vue'

import Layout3 from '~/components/layouts/Layout3.vue'

export default Vue.extend({
  components: {
    Layout3,
  },
  methods: {
    goBack() {
      this.$emit('back')
    },
  },
})
</script>
