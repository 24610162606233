import Layout3 from '@/components/layouts/Layout3.vue';
import PhoneInput from '@/components/PhoneInput.vue';
export default defineComponent({
  components: {
    Layout3: Layout3,
    PhoneInput: PhoneInput
  },
  props: {
    loading: Boolean
  },
  setup: function setup() {
    var userStore = useUserStore();
    var phoneNumber = computed(function () {
      var _userStore$attributes, _userStore$attributes2;
      return (_userStore$attributes = (_userStore$attributes2 = userStore.attributes.mobile) === null || _userStore$attributes2 === void 0 ? void 0 : _userStore$attributes2[0]) !== null && _userStore$attributes !== void 0 ? _userStore$attributes : '';
    });
    return {
      phoneNumber: phoneNumber
    };
  },
  data: function data() {
    return {
      phone: {
        compliant: false,
        value: ''
      }
    };
  },
  created: function created() {
    this.phone.value = this.phoneNumber;
  },
  methods: {
    submitNumber: function submitNumber() {
      this.$emit('submit', this.phone.value);
    },
    goBack: function goBack() {
      this.$emit('back');
    }
  }
});