import _defineProperty from "@babel/runtime/helpers/esm/defineProperty";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.symbol.js";
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.object.get-own-property-descriptor.js";
import "core-js/modules/es.object.get-own-property-descriptors.js";
import "core-js/modules/es.object.keys.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.regexp.to-string.js";
import "core-js/modules/web.dom-collections.for-each.js";
import examples from 'libphonenumber-js/examples.mobile.json';
import { findPhoneNumbersInText, getCountryCallingCode, getExampleNumber, parsePhoneNumberFromString } from 'libphonenumber-js/mobile';
export default defineComponent({
  name: 'PhoneInput',
  props: {
    label: String,
    value: String,
    disabled: Boolean
  },
  setup: function setup(context, _ref) {
    var _context$value;
    var emit = _ref.emit;
    var _storeToRefs = storeToRefs(useLanguageStore()),
      countryList = _storeToRefs.countryList;
    var countryCode = ref('CH');
    var phoneNumber = ref((_context$value = context.value) !== null && _context$value !== void 0 ? _context$value : '');
    var parsedPhoneNumber = computed(function () {
      return parsePhoneNumberFromString(phoneNumber.value, countryCode.value);
    });
    onMounted(function () {
      if (context.value) {
        var _parsed$, _parsed$$number, _parsed$2, _parsed$2$number;
        /* correctly load user's existing number */
        var parsed = findPhoneNumbersInText("+".concat(context.value));
        countryCode.value = (parsed === null || parsed === void 0 ? void 0 : (_parsed$ = parsed[0]) === null || _parsed$ === void 0 ? void 0 : (_parsed$$number = _parsed$.number) === null || _parsed$$number === void 0 ? void 0 : _parsed$$number.country) || countryCode.value;
        phoneNumber.value = (parsed === null || parsed === void 0 ? void 0 : (_parsed$2 = parsed[0]) === null || _parsed$2 === void 0 ? void 0 : (_parsed$2$number = _parsed$2.number) === null || _parsed$2$number === void 0 ? void 0 : _parsed$2$number.nationalNumber) || phoneNumber.value;
      }
      nextTick(function () {
        formatNumber();
      });
    });
    var isValidInput = computed(function () {
      var _parsedPhoneNumber$va;
      return Boolean((_parsedPhoneNumber$va = parsedPhoneNumber.value) === null || _parsedPhoneNumber$va === void 0 ? void 0 : _parsedPhoneNumber$va.isValid());
    });
    watchEffect(function () {
      var _parsedPhoneNumber$va2;
      if (context.disabled) return;
      var number = (_parsedPhoneNumber$va2 = parsedPhoneNumber.value) === null || _parsedPhoneNumber$va2 === void 0 ? void 0 : _parsedPhoneNumber$va2.number;
      if (number) {
        emit('input', number.substring(1));
      } else {
        emit('input', '');
      }
      emit('phone-number-compliant', isValidInput.value);
    });
    var exampleNumber = computed(function () {
      var _exampleNumber$format;
      if (!countryCode.value) return '';
      var exampleNumber = getExampleNumber(countryCode.value, examples);
      return (_exampleNumber$format = exampleNumber === null || exampleNumber === void 0 ? void 0 : exampleNumber.formatNational()) !== null && _exampleNumber$format !== void 0 ? _exampleNumber$format : '';
    });
    var formatNumber = function formatNumber() {
      if (parsedPhoneNumber.value) {
        phoneNumber.value = parsedPhoneNumber.value.formatNational();
        if (parsedPhoneNumber.value.country && parsedPhoneNumber.value.country !== countryCode.value) {
          countryCode.value = parsedPhoneNumber.value.country;
        }
      }
    };
    var countriesWithPhoneCode = computed(function () {
      return countryList.value.map(function (country) {
        var countryCallingCode = '';
        try {
          countryCallingCode = getCountryCallingCode(country.value).toString();
        } catch (e) {}
        return _objectSpread(_objectSpread({}, country), {}, {
          code: countryCallingCode
        });
      }).filter(function (country) {
        return country.code !== '' || country.divider;
      });
    });
    return {
      countryCode: countryCode,
      phoneNumber: phoneNumber,
      exampleNumber: exampleNumber,
      isValidInput: isValidInput,
      countriesWithPhoneCode: countriesWithPhoneCode,
      formatNumber: formatNumber
    };
  }
});